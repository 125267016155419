import React from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import ResultContainer from "../layout/ResultContainer"
import Message from "../../assets/email.svg"
import Price from "../../assets/question.svg"
import Call from "../../assets/smartphone.svg"
import Internet from "../../assets/plugs.svg"

const LoadingText = styled.div`
  text-align: center;
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f9f8fd;
  text-align: center;
  background-color: white;
  @media ${device.tablet} {
    padding: 0px;
  }
`

const TitleContainer = styled.div`
  align-items: center;
  @media ${device.tablet} {
    margin: 12px;
  }
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5rem;
  @media ${device.tablet} {
    grid-template-columns: repeat(1, 1fr);
    display: block;
  }
`
const HeaderContainer = styled.div`
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
  margin: 1rem 8rem;
  @media ${device.tablet} {
    margin: 0.3rem;
    padding: 0.3rem;
  }
`

const Filter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  padding: 1rem 1rem;
  margin-top: 2rem;
  align-items: center;
  @media ${device.tablet} {
    background-color: white;
    margin: 3rem 0rem;
  }
`

const HeaderH1 = styled.h1`
  color: #3a3a3a;
  font-size: 3rem;
  padding: 1rem;
  font-weight: 800;
  text-transform: capitalize;
  @media ${device.tablet} {
    font-size: 2rem;
    font-weight: 900;
  }
  @media ${device.mobileS} {
    font-size: 2rem;
    font-weight: 900;
  }
`

const SloganText = styled.div`
  font-weight: 300;
  font-size: 28px;
  color: #616160;
  margin: 0rem 6rem;
  @media ${device.tablet} {
    font-size: 20px;
    margin: 0rem;
  }
`

const FilterText = styled.div`
  font-weight: 700;
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-height: 50px;
`

const BigText = styled.span`
  font-size: 35px;
  color: darkgray;
  padding: 10px;
`
const ResultText = styled.div`
  font-size: 1rem;
  font-weight: 300;
  padding: 2rem;
`

const ResultHighlight = styled.div`
  font-size: 1.5rem;
  color: #00c29f;
  font-weight: 800;
  padding: 1rem;
`

const IconMessage = styled(Message)`
  width: 50px;
  height: 50px;
  margin: 0px auto;
`

const IconInternet = styled(Internet)`
  width: 50px;
  height: 50px;
  margin: 0px auto;
`

const IconCall = styled(Call)`
  width: 50px;
  height: 50px;
  margin: 0px auto;
`
const FilterButtonContainer = styled.div`
  display: "flex";
  justify-content: center;
`

const InternetButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
`

const FilterButton = styled.button`
  background: ${props => (props.active ? "#F0BC5E" : "#fae8c8")};
  color: #150e00;
  border: none;
  min-width: 80px;
  margin: 0.3rem;
  font-size: 1.3rem;
  font-weight: ${props => (props.active ? "500" : "400")};
  min-height: 60px;
  border-radius: 10px;
`

const IconPrice = styled(Price)`
  width: 50px;
  height: 50px;
  margin: 0px auto;
`

const InternetContainer = styled.div``
const InternetHeader = styled.p`
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0rem;
  padding: 0.1rem;
  min-height: 30px;
`

const InternetSpeed = styled.p`
  font-size: 1.3rem;
  font-weight: 700;
  padding: 0.1rem;
`
const InternetPreFix = styled.span`
  font-size: 0.7rem;
  font-weight: 200;
  padding: 0.1rem;
`

const CompareHeader = props => {
  const plansHook = props.plansHook
  const handlePriceFilter = event => {
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `Vertailu ${props.pageName}`, {
          event_category: "vertailuPriceFilter",
          event_label: event,
        })
    plansHook.setPrice(event)
  }
  const handleTypeFilter = event => {
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `Vertailu ${props.pageName}`, {
          event_category: "vertailuTypeFilter",
          event_label: event,
        })
    plansHook.setTypeFilter(event)
  }
  const handleInternetFilter = event => {
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `Vertailu ${props.pageName}`, {
          event_category: "vertailuInternetFilter",
          event_label: event,
        })
    plansHook.setInternet(event)
  }

  return (
    <Header>
      <HeaderContainer>
        <TitleContainer>
          <HeaderH1>{props.title}</HeaderH1>
          <SloganText>{props.slogan}</SloganText>
        </TitleContainer>
        {/* {!props.notShowFilters && (
          <FiltersContainer>
            <Filter>
              <IconPrice />
              <FilterText>
                Paljonko voit enintään maksaa liittymästä kuukaudessa?
              </FilterText>
              <FilterButtonContainer>
                <FilterButton
                  active={plansHook.priceFilter === 10}
                  onClick={() => handlePriceFilter(10)}
                >
                  10€
                </FilterButton>
                <FilterButton
                  active={plansHook.priceFilter === 20}
                  onClick={() => handlePriceFilter(20)}
                >
                  20€
                </FilterButton>
                <FilterButton
                  active={plansHook.priceFilter === 25}
                  onClick={() => handlePriceFilter(25)}
                >
                  25€
                </FilterButton>
                <FilterButton
                  active={plansHook.priceFilter === 30}
                  onClick={() => handlePriceFilter(30)}
                >
                  30€
                </FilterButton>
                <FilterButton
                  active={plansHook.priceFilter === 40}
                  onClick={() => handlePriceFilter(40)}
                >
                  40€
                </FilterButton>
                <FilterButton
                  active={plansHook.priceFilter === 50}
                  onClick={() => handlePriceFilter(50)}
                >
                  50€
                </FilterButton>
              </FilterButtonContainer>
            </Filter>

            <Filter>
              <IconMessage />
              <FilterText>Puhelut ja viestit?</FilterText>
              <FilterButtonContainer>
                <FilterButton
                  onClick={() => handleTypeFilter("all")}
                  active={plansHook.typeFilter === "all"}
                >
                  Kaikki
                </FilterButton>
                <FilterButton
                  onClick={() => handleTypeFilter("rajaton")}
                  active={plansHook.typeFilter === "rajaton"}
                >
                  Rajaton
                </FilterButton>
                <FilterButton
                  onClick={() => handleTypeFilter("kaytto")}
                  active={plansHook.typeFilter === "kaytto"}
                >
                  Käytön mukaan
                </FilterButton>
              </FilterButtonContainer>
            </Filter>
            <Filter final>
              <IconInternet />
              <FilterText>Paljonko netin mininopeuden pitää olla?</FilterText>
              <InternetButtonContainer>
                <FilterButton
                  onClick={() => handleInternetFilter(0)}
                  active={plansHook.internetFilter === 0}
                >
                  <InternetContainer>
                    <InternetHeader>Ei nettiä</InternetHeader>
                    <InternetSpeed>
                      0 <InternetPreFix>mbit/s</InternetPreFix>
                    </InternetSpeed>
                  </InternetContainer>
                </FilterButton>
                <FilterButton
                  onClick={() => handleInternetFilter(10)}
                  active={plansHook.internetFilter === 10}
                >
                  <InternetContainer>
                    <InternetHeader>Satunnainen käyttö</InternetHeader>
                    <InternetSpeed>
                      10 <InternetPreFix>mbit/s</InternetPreFix>
                    </InternetSpeed>
                  </InternetContainer>
                </FilterButton>
                <FilterButton
                  onClick={() => handleInternetFilter(100)}
                  active={plansHook.internetFilter === 100}
                >
                  <InternetContainer>
                    <InternetHeader>Päivittäinen käyttö</InternetHeader>
                    <InternetSpeed>
                      100 <InternetPreFix>mbit/s</InternetPreFix>
                    </InternetSpeed>
                  </InternetContainer>
                </FilterButton>
                <FilterButton
                  onClick={() => handleInternetFilter(300)}
                  active={plansHook.internetFilter === 300}
                >
                  <InternetContainer>
                    <InternetHeader>Jatkuva käyttö</InternetHeader>
                    <InternetSpeed>
                      300 <InternetPreFix>mbit/s</InternetPreFix>
                    </InternetSpeed>
                  </InternetContainer>
                </FilterButton>
                <FilterButton
                  onClick={() => handleInternetFilter(500)}
                  active={plansHook.internetFilter === 500}
                >
                  <InternetContainer>
                    <InternetHeader>Tehokas käyttö</InternetHeader>
                    <InternetSpeed>
                      500 <InternetPreFix>mbit/s</InternetPreFix>
                    </InternetSpeed>
                  </InternetContainer>
                </FilterButton>
                <FilterButton
                  onClick={() => handleInternetFilter(1000)}
                  active={plansHook.internetFilter === 1000}
                >
                  <InternetContainer>
                    <InternetHeader>Paras mahdollinen</InternetHeader>
                    <InternetSpeed>
                      1000 <InternetPreFix>mbit/s</InternetPreFix>
                    </InternetSpeed>
                  </InternetContainer>
                </FilterButton>
              </InternetButtonContainer>
            </Filter>
          </FiltersContainer>
        )} */}
      </HeaderContainer>
      {/* {plansHook.filteredPlans.length > 0 ? (
        <>
          <ResultHighlight>Onnea!</ResultHighlight>
          <ResultText>
            Löysimme sinulle <BigText>{plansHook.filteredPlans.length}</BigText>{" "}
            liittymää
          </ResultText>
        </>
      ) : (
        <>
          <ResultHighlight>Onnea!</ResultHighlight>
          <ResultText>
            Löysimme sinulle <BigText>{plansHook.filteredPlans.length}</BigText>{" "}
            liittymää
          </ResultText>
        </>
      )}
      {plansHook.loading ? (
        <LoadingText>Ladataan..</LoadingText>
      ) : (
        <ResultContainer
          pageName={props.pageName}
          plansHook={props.plansHook}
        />
      )} */}
    </Header>
  )
}

export default CompareHeader

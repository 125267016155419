import React from "react"
import { useState } from "react"
import styled from "styled-components"
import { device } from "../../utils/mediaQueries"
import ResultCard from "../resultComponents/ResultCard"
import ResultImage from "../images/ResultImage"
import OperatorImage from "../images/OperatorImage"

const OthersHeader = styled.h3`
  border-top: 1px solid lightgray;
  font-size: 18px;
  font-weight: 800;
  padding: 20px;
  margin-top: 70px;
`
const Container = styled.div`
  padding: 1rem 10rem;
  background-color: #f2f2f2;
  text-align: center;
  @media ${device.tablet} {
    padding: 0px;
  }
`

const ShowOthersButton = styled.button`
  border: 1px solid lightgray;
  color: blue;
  background-color: white;
  outline: none;
  font-size: 17px;
  font-weight: 800;
  padding: 10px;
  margin: 10px 20px;
`
const GreetingText = styled.h3`
  font-size: 2rem;
  font-weight: 800;
`

const AlternativeText = styled.h3`
  font-size: 1.2rem;
  font-weight: 300;
`

const NoResultsText = styled.div`
  font-size: 25px;
  color: black;
  padding: 2rem;
`

const ResultContainer = props => {
  const [showOther, setShowOthers] = useState(true)

  const showOthersHandler = () => {
    if (!process.env.GATSBY_MY_ENVIRONMENT)
      typeof window !== "undefined" &&
        window.gtag("event", `MuutNappula`, {
          event_category: "nettiliittymaVertailuMuut",
          event_label: "Muut",
        })
    setShowOthers(false)
  }

  const results = props.plansHook.filteredPlans.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        save={(props.plansHook.priceFilter - plan.price).toFixed(2)}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        pageName={props.pageName}
      />
    )
  })
  const others = props.plansHook.originalPlans.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        save={(props.plansHook.priceFilter - plan.price).toFixed(2)}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        pageName={props.pageName}
      />
    )
  })
  return (
    <Container>
      <div>{results}</div>
      <OthersHeader>Haluatko nähdä myös muut vaihtoehdot?</OthersHeader>
      <ShowOthersButton onClick={() => showOthersHandler()}>
        Näytä muut {props.plansHook.originalPlans.length} vaihtoehtoa
      </ShowOthersButton>
      <div hidden={showOther}>{others}</div>
    </Container>
  )
}

export default ResultContainer

import { useState, useEffect } from "react"

export const usePlans = plans => {
  const [originalPlans] = useState(plans)
  const [filteredPlans, setFilteredPlans] = useState(originalPlans)
  const [otherPlans, setOtherPlans] = useState(originalPlans)
  const [priceFilter, setPriceFilter] = useState(25)
  const [typeFilter, setTypeFilter] = useState("all")
  const [internetFilter, setInternetFilter] = useState(0)
  const [initialStart, setInitialStart] = useState(true)
  const [loading, setLoading] = useState(false)
  const [allEmpty, setAllEmpty] = useState(true)

  useEffect(() => {
    filterPlans()
  }, [])

  useEffect(() => {
    if (initialStart) {
      setInitialStart(false)
    } else {
      setLoading(true)
      filterPlans()
    }
  }, [priceFilter, internetFilter, typeFilter])

  const setPrice = price => setPriceFilter(price)
  const setType = type => setTypeFilter(type)
  const setInternet = internet => setInternetFilter(internet)

  const filterPlans = () => {
    const newOthers = []
    const newFiltered = []
    const plans =
      typeFilter === "all"
        ? originalPlans
        : typeFilter === "rajaton"
        ? originalPlans.filter(plan => plan.type === "rajaton")
        : originalPlans.filter(plan => plan.type === "kaytto")

    const filteredPlans = plans.filter(
      plan =>
        plan.price <= priceFilter &&
        parseInt(plan.internetSpeed) >= internetFilter
    )
    setFilteredPlans(filteredPlans)
    setOtherPlans(newOthers)
    setLoading(false)
  }

  return {
    setPrice,
    setTypeFilter,
    typeFilter,
    setInternet,
    filteredPlans,
    otherPlans,
    originalPlans,
    priceFilter,
    internetFilter,
    loading,
    allEmpty,
  }
}
